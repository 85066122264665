
<script>
import confirmButton from "../buttons/confirm-button.vue";
export default {
    props:{
        id:String,
        size:{
            type: String,
            default: null
        },
        title:{
            type: String,
            default: 'Mi modal'
        },
        labelConfirmBtn:{
            type: String,
            default: 'Guardar'
        },
        labelCancelBtn:{
            type: String,
            default: 'Cancelar'
        },
        titleClass:{
            type: String,
            default: 'text-dark font-18'
        },
        showConfirmButton:{
            type: Boolean,
            default: true
        },
        isLoading:{
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            
        };
    },
    components: {
        confirmButton
    },
    emits:['confirm', 'cancel', 'shown', 'hidden'],
    methods:{
        confirm(){
            this.$emit("confirm");
        },
        cancel(){
            this.$emit("cancel");
        },
        shown(){
            this.$emit("shown");
        },
        hidden(){
            this.$emit("hidden");
        }
    },
    mounted() {
        // Previene que Bootstrap maneje el foco cuando se abre un modal de TinyMCE
        document.addEventListener('focusin', (e) => {
            if (e.target.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
                e.stopImmediatePropagation(); 
            }
        });
    }
}
</script>
<template>
    <!-- Modal -->
    <b-modal
      :id="id"
      :title-html="title"
      :title-class="titleClass"
      no-close-on-backdrop
      no-close-on-esc  
      @shown="shown"   
      @hidden="hidden"
      scrollable
      :size="size"
      :auto-focus="false"
    >
        <div class="text-center text-primary qlk-spinner" v-if="$store.state.loading.loading">
            <span aria-hidden="true" class="align-middle spinner-border">                
            </span>
        </div>
        <form @submit.prevent="confirm" autocomplete="off">
            <slot></slot>
        </form>
        <template #modal-footer>
            <b-button
                variant="secondary"
                size="sm"
                class="float-right"
                @click="cancel"
            >
                {{labelCancelBtn}}
            </b-button>
            <confirmButton v-if="showConfirmButton" :label="labelConfirmBtn" btn-class="btn float-right btn-primary btn-sm" @click="confirm"></confirmButton>            
        </template>
    </b-modal>
    <!-- end modal -->
</template>