<script>
import { required, minLength, maxLength, maxValue } from "vuelidate/lib/validators";
import Modal from "@/components/modals/modal-form.vue";
import InfoTooltip from "@/components/widgets/info-tooltip.vue";
import api from '@/helpers/apirest/api';

import {
    storeComputed
} from "@/state/helpers";

const initialState = () => {
    return {
        submitted: false,
        tax_rate: {

            name: '',
            country: '',
            state: '',
            postcodes: '',
            cities: '',
            rate: '',
            priority: '',
            compound: '',
            shipping: true,
            class: 'standard',

            stores: []
        }
    };
}

export default {
    emits: ['onSuccess'],
    props: {
        modalId: String,
        id: Number
    },
    data() {
        return initialState();
    },
    components: {
        Modal,
        InfoTooltip
    },
    computed: {
        ...storeComputed,
    },
    validations: {
        tax_rate: {

            name: { required },
            country: { minLength: minLength(2), maxLength: maxLength(2) },
            state: { minLength: minLength(2), maxLength: maxLength(2) },
            postcodes: {},
            cities: {},
            rate: { maxValue: maxValue(100) },
            priority: {},
            compound: {},
            shipping: {},
            class: {},
            stores: {
                $each: {
                    id: {},
                    order: {},
                }
            }
        }
    },
    methods: {
        async shown() {

            this.$store.dispatch("store/loadList");

            if (this.id) {
                const response = await api.get({
                    url: `tax-rates/${this.id}`,
                    config: {
                        withLoading: true
                    }
                });
                const tax_rate = response?.data?.data;
                if(tax_rate?.stores){
                    const stores = tax_rate?.stores.map(store => {
                        store.pivot.id = store.pivot.store_id;
                        store.pivot.is_update = true;
                        return store?.pivot;
                    });
                    tax_rate.stores = stores;
                }
                this.tax_rate = tax_rate;
            }
        },
        addStoreRow(){
            this.tax_rate.stores.push({
                id: '',
                order: null,
            });
        },
        deleteStoreRow(key){
            this.tax_rate.stores.splice(key, 1);
        },
        async confirm() {

            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            const data = this.tax_rate;
            data.media_id = this.tax_rate?.media?.id;
            await api.save({
                url: 'tax-rates',
                id: this.tax_rate?.id,
                data: data,
                config: {
                    withLoading: true
                }
            });

            this.$bvModal.hide(this.modalId);
            this.$emit("onSuccess");
        },
        cancel() {
            this.$bvModal.hide(this.modalId);
        },
        resetModal() {
            Object.assign(this.$data, initialState());
        },
        showUploadModal() {
            this.$bvModal.show(this.uploadModalId);
        },
    }
};
</script>

<template>
    <Modal :title="'Formulario de impuesto'" :id="modalId" @confirm="confirm" @cancel="cancel" @shown="shown" @hidden="resetModal" :size="'extra-xl'">
        <div class="row">

            <div class="col-lg-6 col-md-12 col-xs-12 mb-2">
                <div class="card h-100">
                    <div class="card-body">
                        <h4 class="card-title">General</h4>
                        <p class="card-title-desc">
                            Ingresa los datos generales de la categoría
                        </p>
                        <div class="row">
                            <div class="form-group col-12">
                                <label for="name">Nombre</label>
                                <p class="text-muted font-13">Introduce un nombre para esta tasa de impuesto.</p>
                                <input id="name" v-model="tax_rate.name" type="text" class="form-control" placeholder="Ingresa un nombre"
                                    :class="{ 'is-invalid': $v.tax_rate.name.$error && submitted }" />
                                <div v-if="!$v.tax_rate.name.required && submitted" class="invalid-feedback">Nombre es requerido</div>
                            </div>
                            <div class="form-group col-12">
                                <label for="country">Código de país</label>
                                <p class="text-muted font-13">Un código de país de 2 dígitos, por ejemplo ES. Déjalo en blanco para aplicarlo a todos.</p>
                                <input id="country" v-model="tax_rate.country" type="text" class="form-control" placeholder="Ingresa un código de país"
                                :class="{ 'is-invalid': $v.tax_rate.country.$error && submitted }" />
                                <div v-if="$v.tax_rate.country.$error && submitted" class="invalid-feedback">Código de país inválido</div>
                            </div>
                            <div class="form-group col-12">
                                <label for="state">Código de provincia</label>
                                <p class="text-muted font-13">Un código de provincia de 2 dígitos, por ejemplo MA. Déjalo en blanco para aplicarlo a todos.</p>
                                <input id="state" v-model="tax_rate.state" type="text" class="form-control" placeholder="Ingresa un código de provincia"
                                :class="{ 'is-invalid': $v.tax_rate.state.$error && submitted }" />
                                <div v-if="$v.tax_rate.state.$error && submitted" class="invalid-feedback">Código de provincia inválido</div>
                            </div>
                            <div class="form-group col-12">
                                <label for="postcodes">Código postal</label>
                                <p class="text-muted font-13">Código postal para esta regla. El punto y coma (;) separa múltiples valores. Déjalo en blanco para que se aplique a todas las áreas. También se pueden usar comodines (*) y rangos de códigos postales numéricos (p. ej. 12345…12350).</p>
                                <input id="postcodes" v-model="tax_rate.postcodes" type="text" class="form-control" placeholder="Ingresa un código postal"
                                :class="{ 'is-invalid': $v.tax_rate.postcodes.$error && submitted }" />
                                <div v-if="$v.tax_rate.postcodes.$error && submitted" class="invalid-feedback">Código postal inválido</div>
                            </div>
                            <div class="form-group col-12">
                                <label for="cities">Ciudad</label>
                                <p class="text-muted font-13">Ciudades para esta regla. Usa punto y coma (;) para separar varios valores. Déjalo en blanco para aplicarlo a todas las ciudades.</p>
                                <input id="cities" v-model="tax_rate.cities" type="text" class="form-control" placeholder="Ingresa un ciudad"
                                :class="{ 'is-invalid': $v.tax_rate.cities.$error && submitted }" />
                                <div v-if="$v.tax_rate.cities.$error && submitted" class="invalid-feedback">Ciudad inválida</div>
                            </div>
                            <div class="form-group col-12">
                                <label for="rate">Tarifa %</label>
                                <p class="text-muted font-13">Introduce una tasa de impuestos (porcentaje) con 4 decimales.</p>
                                <input id="rate" v-model="tax_rate.rate" type="number" class="form-control" placeholder="Ingresa una tarifa"
                                :class="{ 'is-invalid': $v.tax_rate.rate.$error && submitted }" />
                                <div v-if="$v.tax_rate.rate.$error && submitted" class="invalid-feedback">Tarifa inválida</div>
                            </div>
                            <div class="form-group col-12">
                                <label for="priority">Prioridad</label>
                                <p class="text-muted font-13">Elige una prioridad para esta tasa de impuesto. Se utilizará solo 1 tasa por prioridad. Para definir múltiples tasas de impuestos para una sola área tienes que especificar una prioridad distinta por tasa.</p>
                                <input id="priority" v-model="tax_rate.priority" type="number" class="form-control" placeholder="Ingresa un número de prioridad"
                                :class="{ 'is-invalid': $v.tax_rate.priority.$error && submitted }" />
                                <div v-if="$v.tax_rate.priority.$error && submitted" class="invalid-feedback">Prioridad inválida</div>
                            </div>
                            <div class="form-group col-12">
                                <label for="compound">Compuesto</label>
                                <p class="text-muted font-13">Elige si esto es o no una tasa compuesta. Las tasas de impuestos compuestas se aplican antes que otras tasas de impuestos.</p>
                                <div class="custom-control custom-checkbox">
                                    <input
                                        type="checkbox"
                                        v-model="tax_rate.compound"
                                        class="custom-control-input"
                                        id="compound"
                                        :class="{ 'is-invalid': $v.tax_rate.compound.$error && submitted }"
                                    />
                                    <label class="custom-control-label" for="compound">Sí</label>
                                </div>
                            </div>
                            <div class="form-group col-12">
                                <label for="shipping">Envío</label>
                                <p class="text-muted font-13">Elige si esta tasa de impuesto se aplica también al envío o no.</p>
                                <div class="custom-control custom-checkbox">
                                    <input
                                        type="checkbox"
                                        v-model="tax_rate.shipping"
                                        class="custom-control-input"
                                        id="shipping"
                                        :class="{ 'is-invalid': $v.tax_rate.shipping.$error && submitted }"
                                    />
                                    <label class="custom-control-label" for="shipping">Sí</label>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-xs-12 mb-2">
                <div class="card h-100">
                    <div class="card-body">
                        <h4 class="card-title">
                            Tiendas
                        </h4>
                        <p class="card-title-desc">
                            Asocia la categoría con cada una de tus tiendas
                        </p>
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">Tienda</th>
                                    <th scope="col">Orden <InfoTooltip title="Indica el orden que aparecerá en las consultas." /></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(store, i) in tax_rate?.stores" :key="i" :set="v = $v.tax_rate.stores.$each[i]" class="">
                                    <td>
                                        <div class="form-group">
                                            <select v-model="store.id" class="form-control"
                                                :class="{ 'is-invalid': v.id.$error && submitted }">
                                                <option value="">Select</option>
                                                <option v-for="option in stores" v-bind:value="option.id" :key="option.id">
                                                    {{ option.name }}
                                                </option>
                                            </select>                                            
                                        </div>
                                    </td>
                                    <td>
                                        <div class="form-group">
                                            <input 
                                                type="number"
                                                v-model="store.order"
                                                class="form-control"
                                                :class="{ 'is-invalid': v.order.$error }"
                                                placeholder="Orden"
                                            />
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <a
                                            href="javascript:void(0);"
                                            class="text-danger"
                                            v-b-tooltip.hover
                                            title="Eliminar"
                                            @click="deleteStoreRow(i)"
                                            v-if="!store?.is_update"
                                        >
                                            <i class="mdi mdi-trash-can font-size-18"></i>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colspan="4" class="text-right">
                                        <a
                                            href="javascript:void(0);"
                                            class="text-success"
                                            @click="addStoreRow()"
                                        >
                                            <i class="mdi mdi-plus-circle font-size-18"></i> Agregar nueva tienda
                                        </a>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>                       
                    </div>
                </div>
            </div>
        </div>
    </Modal>
</template>